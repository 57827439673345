function obtenirImage(collectionImages, id) {
  try {
    let image
    for (const x in collectionImages) {
      image = collectionImages[x]
      if (image.code === id) return image
    }
  } catch (error) {
    /*erreurs possibles : problème dans le fichier, ou plus souvent, problème de casse entre
    le fichier yaml et le fichier physique*/
    throw new Error(`Erreur id image : ${id} ${error}`)
  }
  throw new Error(
    `Erreur : Aucune image portant l'identifiant ${id} n'a été trouvée.`
  )
}

function obtenirImages(resultatsRequeteGraphQL, ...nomsObjetsGraphQLDesImages) {
  let resultat = []

  for (const objet in resultatsRequeteGraphQL) {
    if (nomsObjetsGraphQLDesImages.includes(objet))
      resultat = resultat.concat(resultatsRequeteGraphQL[`${objet}`].nodes)
  }

  return resultat
}

function obtenirPage(resultatsRequeteGraphQL, nomObjetGraphQL) {
  if (
    resultatsRequeteGraphQL[nomObjetGraphQL] == null ||
    resultatsRequeteGraphQL == undefined
  ) {
    throw new Error(
      `Aucun objet ${nomObjetGraphQL} n'existe dans la requête GraphQL.`
    )
  }

  if (resultatsRequeteGraphQL[nomObjetGraphQL].nodes.length === 0) {
    throw new Error(
      `L'objet ${nomObjetGraphQL} ne contient aucune page. Vérifiez la requête GraphQL.`
    )
  } else if (resultatsRequeteGraphQL[nomObjetGraphQL].nodes.length > 1) {
    throw new Error(
      `La résultat de la requête GraphQL retourne plusieurs résultats.
       Il n'en faut qu'un seul : vérifiez qu'un filtre sur l'identifiant de page a été utilisé dans la requête.`
    )
  }
  return transformerPageYamlEnPage(
    resultatsRequeteGraphQL[nomObjetGraphQL].nodes[0]
  )
}

function obtenirPages(resultatsRequeteGraphQL, ...nomsObjetsGraphQLDesPages) {
  let resultat = []

  for (const objet in resultatsRequeteGraphQL) {
    if (nomsObjetsGraphQLDesPages.includes(objet))
      resultat = resultat.concat(resultatsRequeteGraphQL[`${objet}`].nodes)
  }

  return resultat
}

function obtenirCartesLiensVersAutresPages(
  resultatsRequeteGraphQL,
  nomObjetGraphQL
) {
  if (
    resultatsRequeteGraphQL[nomObjetGraphQL] == null ||
    resultatsRequeteGraphQL == undefined
  ) {
    throw new Error(
      `Aucun objet ${nomObjetGraphQL} n'existe dans la requête GraphQL.`
    )
  }

  return resultatsRequeteGraphQL[nomObjetGraphQL].nodes
}

function transformerPageYamlEnPage(pageYaml) {
  const page = {}
  page.code = pageYaml.code

  page.titre = pageYaml.titre
  page.titrePourSEOEtLien = pageYaml.titre_pour_seo_et_lien
  page.description = pageYaml.description
  page.motsClefs = pageYaml.mots_clefs
  page.url = pageYaml.url == null ? "" : pageYaml.url
  page.urlPartage = pageYaml.url_partage
  page.ordreCarte = pageYaml.ordre_carte
  page.langue = pageYaml.langue
  page.vignette = pageYaml.vignette
  page.typeJSONLD = pageYaml.type_json_ld == null ? "" : pageYaml.type_json_ld
  page.partageReseauxSociaux = pageYaml.partage_reseaux_sociaux
  page.datePublication = Date.parse(pageYaml.date_publication)
  page.dateModification = Date.parse(pageYaml.date_modification)

  return page
}

function comparerOrdreImages(image1, image2) {
  if (image1.ordre < image2.ordre) {
    return -1
  }
  if (image1.ordre > image2.ordre) {
    return 1
  }
  return 0
}

function chaineNulleOuVide(chaine) {
  return chaine == null || chaine == undefined || chaine === ""
}

function chaineVideSiNullEtTrim(chaine) {
  return chaine == null || chaine == undefined ? "" : chaine.trim()
}

function objetEstDefini(objet) {
  return objet != null && objet != undefined
}

function elementEstVisibleParId(idElement) {
  return elementEstVisible(obtenirElement(idElement))
}

function elementEstVisible(element) {
  return element.style.display !== "none"
}

function masquerElementParId(idElement) {
  const element = obtenirElement(idElement)
  masquerElement(element)
}

function masquerElement(element, affichageOrigine = "block") {
  if (elementEstVisible(element)) element.style.display = "none"
  else element.style.display = affichageOrigine
}

function obtenirAffichageElement(element) {
  return element.style.display
}

function obtenirElementParId(idElement) {
  return document.getElementById(idElement)
}

function obtenirElement(idElement) {
  const element = obtenirElementParId(idElement)
  if (!objetEstDefini(element))
    throw new Error(
      `Aucun élément à masquer portant l'id ${idElement} n'a été trouvé.`
    )
  return element
}

function supprimerDernierCaractere(chaine) {
  return chaine.slice(0, -1)
}

function obtenirDernierCaractere(chaine) {
  return chaine[chaine.length - 1]
}

function verifierSiPageAEteAppeleeEnInterne(propsPageAppelee) {
  return (
    propsPageAppelee.location.state != null &&
    propsPageAppelee.location.state.appelInterne === true
  )
}

function definirInnerHTML(html) {
  return { __html: `${html}` }
}

module.exports = {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirPages,
  obtenirCartesLiensVersAutresPages,
  comparerOrdreImages,
  chaineNulleOuVide,
  chaineVideSiNullEtTrim,
  objetEstDefini,
  obtenirElement,
  masquerElement,
  elementEstVisible,
  elementEstVisibleParId,
  masquerElementParId,
  obtenirAffichageElement,
  supprimerDernierCaractere,
  obtenirDernierCaractere,
  verifierSiPageAEteAppeleeEnInterne,
  definirInnerHTML,
}
