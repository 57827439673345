import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"

import { chaineNulleOuVide, objetEstDefini } from "../../js/client-es/utils"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pages: allPagesCompilationYaml {
      ...FragmentUrlPageYaml
    }
  }
`

export default function Lien(props) {
  if (!chaineNulleOuVide(props.codePageCible)) {
    const page = rechercherUrl(useStaticQuery(requete), props)
    return (
      <Link
        to={
          page.url + (chaineNulleOuVide(props.ancre) ? "" : "/#" + props.ancre)
        }
        id={props.id}
        aria-label={page.titre}
        state={{ appelInterne: true }}
        className={props.classeCSS}
        onClick={props.traitementAAppelerSurClick}
      >
        {obtenirTexteLienPageInterne(props, page)}
      </Link>
    )
  } else if (!chaineNulleOuVide(props.urlExterne)) {
    return (
      <a
        href={props.urlExterne}
        aria-label={props.label}
        target="_blank"
        rel="noreferrer noopener"
        onClick={props.traitementAAppelerSurClick}
        id={props.id}
      >
        {obtenirTexteLienUrlExterne(props)}
      </a>
    )
  } else if (objetEstDefini(props.traitementAAppelerSurClick)) {
    return (
      <div
        className="boutique-icones-conteneur-texte"
        onClick={() => props.traitementAAppelerSurClick()}
        style={{ cursor: "pointer" }}
        aria-label={props.label}
        id={props.id}
      >
        {obtenirTexteLienUrlExterne(props)}
      </div>
    )
  } else {
    console.log(props)
    throw new Error(
      `L'identifiant de la page de destination du lien ou l'url externe du lien ou la fonction javascript à appeler n'a pas été indiquée.`
    )
  }
}

function rechercherUrl(resultatsRequeteGraphQL, props) {
  const pages = resultatsRequeteGraphQL.pages.nodes
  for (const p of pages) {
    if (p.code === props.codePageCible) return { url: p.url, titre: p.titre }
  }
  throw new Error(
    `Aucune URL pour la page id=${props.codePageCible} n'a été trouvée.`
  )
}

function obtenirTexteLienPageInterne(props, page) {
  if (objetEstDefini(props.children)) {
    return props.children
  } else {
    return page.titre
  }
}

function obtenirTexteLienUrlExterne(props) {
  if (objetEstDefini(props.children)) {
    return props.children
  } else {
    return props.urlExterne
  }
}

Lien.propTypes = {
  /*pour ESLint : ça oblige pour les appelant à renseigner la propriété dans les props. Sinon, une erreur apparait dans la console*/
  id: PropTypes.string,
  ancre: PropTypes.string,
  codePageCible: PropTypes.string,
  urlExterne: PropTypes.string,
  children: PropTypes.any,
  props: PropTypes.object,
  classeCSS: PropTypes.string,
  label: PropTypes.string,
  traitementAAppelerSurClick: PropTypes.func,
}
