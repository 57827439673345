function jsonLDArticle(
  urlPage,
  titre,
  auteur,
  urlPubliquesVignettes,
  urlLogo,
  datePublication,
  dateModification
) {
  /*https://developers.google.com/search/docs/data-types/article*/
  let resultat = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${urlPage}`,
    },
    headline: `${titre}`,
    image: urlPubliquesVignettes,
    datePublished: `${new Date(datePublication).toISOString()}`,
    dateModified: `${new Date(dateModification).toISOString()}`,
    author: {
      "@type": "Person",
      name: `${auteur}`,
    },
    publisher: {
      "@type": "Organization",
      name: `${auteur}`,
      logo: {
        "@type": "ImageObject",
        url: `${urlLogo}`,
      },
    },
  }

  return resultat
}

function jsonLDWebSite(titre, description, auteur, urlLogo) {
  /*https://developers.google.com/search/docs/data-types/article*/
  let resultat = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: `${titre}`,
    description: `${description}`,
    publisher: {
      "@type": "Organization",
      name: `${auteur}`,
      logo: {
        "@type": "ImageObject",
        url: `${urlLogo}`,
      },
    },
  }

  return resultat
}

function jsonLDFAQ(titre, questionsEtReponses) {
  const resultat = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
  }

  const resultatsQA = []

  if (questionsEtReponses === undefined || questionsEtReponses === null)
    throw new Error(
      "La collection de questions et réponses n'a pas été définie."
    )

  for (const item of questionsEtReponses) {
    if (item.question === undefined || item.question === null) {
      throw new Error("Aucune question n'a été renseignée.")
    }
    if (item.reponse === undefined || item.question === null) {
      throw new Error("Aucune réponse n'a été renseignée.")
    }
    const qa = {
      "@type": "Question",
      name: `${item.question}`,
      acceptedAnswer: {
        "@type": "Answer",
        text: `${item.reponse}`,
      },
    }
    resultatsQA.push(qa)
  }

  resultat.mainEntity = resultatsQA

  return resultat
}

function obtenirHauteurImagePourUnFormat(largeurImage, format) {
  let numerateur = format.split("x")[0]
  let denominateur = format.split("x")[1]

  return (largeurImage * denominateur) / numerateur
}

module.exports = {
  jsonLDArticle,
  jsonLDWebSite,
  jsonLDFAQ,
  obtenirHauteurImagePourUnFormat,
}
