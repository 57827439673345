import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {
  jsonLDArticle,
  jsonLDWebSite,
  jsonLDFAQ,
  obtenirHauteurImagePourUnFormat,
} from "../../js/client-es/seo-utils"

/*informations provenant du fichier gatsby-config.js*/
const getSiteMetadata = graphql`
  query {
    site {
      siteMetadata {
        titre: title
        description: description
        motsClefs: keywords
        auteur: author
        url: siteUrl
        utilisateurTwitter: twitterUsername
        icone
        logo
        image
        largeurVignetteParDefaut: largeurVignettePourHeaderHtmlParDefaut
        formatVignetteParDefaut: formatVignettePourHeaderHtmlParDefaut
        langueParDefaut
      }
    }
  }
`

export default function SEO({ page }) {
  const { site } = useStaticQuery(getSiteMetadata)
  let {
    titre,
    description,
    motsClefs,
    auteur,
    url,
    utilisateurTwitter,
    image,
    icone,
    logo,
    langueParDefaut,
    largeurVignetteParDefaut,
    formatVignetteParDefaut,
  } = site.siteMetadata

  let urlPage = url
  let urlSite = url
  let langue = langueParDefaut
  let urlPubliqueVignette = image
  let urlPubliquesVignettes = image

  if (page !== undefined) {
    /*on prend en priorité les informations du conteneur page*/
    verifierpage(page)
    titre = page.titrePourSEOEtLien
    description = page.description
    motsClefs = page.motsClefs
    langue = page.langue
    urlPage = page.urlPartage
    urlPubliqueVignette = page.vignette.url_publique
    urlPubliquesVignettes = page.vignette.url_publiques
  }

  return (
    <Helmet htmlAttributes={{ lang: langue }} title={titre}>
      <meta name="description" content={description} />
      <meta name="keywords" content={motsClefs} />
      <meta name="author" content={auteur} />
      <meta name="url" content={urlPage} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      {/*Carte de partage Facebook}*/}
      <meta property="og:url" content={urlPage} />
      <meta property="og:title" content={titre} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={urlSite + urlPubliqueVignette} />
      <meta property="og:image:type" content="image/jpeg" />)
      <meta property="og:image:alt" content={titre} />)
      <meta property="og:image:width" content={largeurVignetteParDefaut} />
      <meta
        name="og:image:height"
        content={obtenirHauteurImagePourUnFormat(
          largeurVignetteParDefaut,
          formatVignetteParDefaut
        )}
      />
      {/*Carte de partage Twitter*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={urlPage} />
      <meta name="twitter:site" content={utilisateurTwitter} />
      <meta name="twitter:creator" content={utilisateurTwitter} />
      <meta name="twitter:title" content={titre} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={urlSite + urlPubliqueVignette} />
      <meta name="twitter:image:width" content={largeurVignetteParDefaut} />
      <meta
        name="twitter:image:height"
        content={obtenirHauteurImagePourUnFormat(
          largeurVignetteParDefaut,
          formatVignetteParDefaut
        )}
      />
      <link rel="icon" type="image/png" href={icone}></link>
      {genererJSONLD(
        urlPage,
        titre,
        description,
        auteur,
        urlPubliquesVignettes,
        logo,
        page
      )}
    </Helmet>
  )
}

function verifierpage(page) {
  if (page.datePublication === undefined)
    throw new Error(`La date de publication de la page n'a pas été définie.`)

  if (page.dateModification === undefined)
    throw new Error(`La date de modification de la page n'a pas été définie.`)
}

function genererJSONLD(
  urlPage,
  titre,
  description,
  auteur,
  urlPubliquesVignettes,
  urlLogo,
  page
) {
  if (page !== undefined) {
    if (page.typeJSONLD.toUpperCase().trim() === "ARTICLE") {
      return (
        <script type="application/ld+json">
          {JSON.stringify(
            jsonLDArticle(
              urlPage,
              titre,
              auteur,
              urlPubliquesVignettes,
              urlLogo,
              page.datePublication,
              page.dateModification
            )
          )}
        </script>
      )
    } else if (page.typeJSONLD.toUpperCase().trim() === "FAQ") {
      if (page.jsonLDFAQ === undefined || page.jsonLDFAQ === null) {
        throw new Error(
          `Le type JSON LD de la page est "FAQ" mais aucun objet jsonLDFAQ n'a été fourni dans le conteneur d'informations page ${page.code}`
        )
      }
      return (
        <script type="application/ld+json">
          {JSON.stringify(jsonLDFAQ(titre, page.jsonLDFAQ))}
        </script>
      )
    } else {
      return (
        <script type="application/ld+json">
          {JSON.stringify(jsonLDWebSite(titre, description, auteur, urlLogo))}
        </script>
      )
    }
  } else {
    return (
      <script type="application/ld+json">
        {JSON.stringify(jsonLDWebSite(titre, description, auteur, urlLogo))}
      </script>
    )
  }
}

SEO.propTypes = {
  page: PropTypes.object,
  titre: PropTypes.string,
  description: PropTypes.string,
  motsClefs: PropTypes.string,
  image: PropTypes.string,
  repertoire: PropTypes.string,
  nomFichier: PropTypes.string,
}
